// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";

const routes = [
  {
    name: "github",
    icon: <GitHubIcon />,
    href: "https://www.github.com/dedSyn4ps3",
  },
];

export default routes;
