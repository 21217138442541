// Material Kit 2 React components
import MKTypography from "components/MKTypography";

const date = new Date().getFullYear();

export default {
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Copyright &copy; {date} {"  "}
      <MKTypography
        component="a"
        href="https://www.nullreturn-it.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Null-Return IT Services &amp; Consulting
      </MKTypography>
    </MKTypography>
  ),
};
